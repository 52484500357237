import React, { useState } from "react";
import Page, { PageButtons } from "./Page";
import Select from "react-select";
import NewDownload from "./NewDownload";
import moment from "moment";
import TopNav from "./TopNav";
import * as S from "./styles";
import * as SVG from "./svgs";
import { Link, useLocation } from "react-router-dom";
import Table, { TR, TD } from "./Table";
import { Query } from "@apollo/react-components";
import { DownloadListQueryData, SortValue, FilterValue } from "./apiTypes";
import { useDialogState, DialogDisclosure, DialogWithBackdrop } from "./Dialog";
import { DownloadsListQuery } from "./queries/download";

const Reports: React.FunctionComponent = () => {
  const addDialog = useDialogState();

  return (
    <Page>
      <TopNav />
      {/* <S.Flex justifyContent="flex-end" mx={3}>
        <DialogDisclosure {...addDialog} as={S.Button}>
          New Custom Report
        </DialogDisclosure>
      </S.Flex> */}
      <S.Panel>
        <S.UnderlinedHeader>
          <S.Flex justifyContent="flex-start">
            Frequently Used Reports
          </S.Flex>
        </S.UnderlinedHeader>
        <Table cols="350px 2fr 1fr">
          <TR header>
            <TD>REPORT NAME</TD>
            <TD>REPORT CATEGORY</TD>
            <TD />
          </TR>
          <TR py={2}>
            <TD>Active Contacts</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/qcgksdjuxedvfhkifnucatimleox.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/qcgksdjuxedvfhkifnucatimleox.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Active Plants</TD>
            <TD>LOCATIONS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/ofeqyebdjziwufbecvfbiibplniz.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/ofeqyebdjziwufbecvfbiibplniz.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Member Companies</TD>
            <TD>COMPANIES</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/gbhkvdmtjorzznarjdqurqsstgud.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/gbhkvdmtjorzznarjdqurqsstgud.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Member Contacts</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/qcgksdjuxedvfhkifnucatimleox.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/qcgksdjuxedvfhkifnucatimleox.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Primary, Secondary and Authorized Contacts</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/coyujykuovnrpuufpaukeuhszges.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/coyujykuovnrpuufpaukeuhszges.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Member Sales/Dues Status (2024)</TD>
            <TD>SALES REPORTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/hurxvynhopcdwlsjbfahblgqpfcv.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/hurxvynhopcdwlsjbfahblgqpfcv.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
        </Table>
      </S.Panel>
      <S.Panel>
        <S.UnderlinedHeader>
          <S.Flex justifyContent="flex-start">
            All Reports
          </S.Flex>
        </S.UnderlinedHeader>
        <Table cols="350px 2fr 1fr">
          <TR header>
            <TD>REPORT NAME</TD>
            <TD>REPORT CATEGORY</TD>
            <TD />
          </TR>
          <TR py={2}>
            <TD>2020 Annual Meeting Registrant Photos</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/lbdhmfourvwewyyruccqtoftrpua.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/lbdhmfourvwewyyruccqtoftrpua.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Achievement Awards Committee</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/uvygnmkgtissfbflfrkewnlkwcme.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/uvygnmkgtissfbflfrkewnlkwcme.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR> 
          <TR py={2}>
            <TD>Achievement Award Submissions</TD>
            <TD>ACHIEVEMENT AWARDS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/xnfahkrxyhkyjpfbnnauaohkexhr.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/xnfahkrxyhkyjpfbnnauaohkexhr.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Active Companies (Members & Non-members)</TD>
            <TD>COMPANIES</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/qfpsyuvwsavxmrkhufybzrqvdqrz.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/qfpsyuvwsavxmrkhufybzrqvdqrz.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
            {/* <TD>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </TD> */}
          </TR>
          <TR py={2}>
            <TD>Active Contacts (Members & Non-members)</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/qcgksdjuxedvfhkifnucatimleox.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/qcgksdjuxedvfhkifnucatimleox.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Active Plants</TD>
            <TD>LOCATIONS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/ofeqyebdjziwufbecvfbiibplniz.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/ofeqyebdjziwufbecvfbiibplniz.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Advocacy Committee</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/nkqaomikqwhdzfutwwuifbefnsbl.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/nkqaomikqwhdzfutwwuifbefnsbl.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Aluminum Foil Committee</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/dftkvlqauiwkqxpkjewdstrjimrr.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/dftkvlqauiwkqxpkjewdstrjimrr.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Board of Directors</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/clkxcluwyfsqlcxusmmmexdnnohf.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/clkxcluwyfsqlcxusmmmexdnnohf.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Business &amp; Industry Research Committee</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/jppnagpzzifaqjrlxkoyvmsyvprb.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/jppnagpzzifaqjrlxkoyvmsyvprb.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Buyer's Guide Company Categories Listing</TD>
            <TD>COMPANIES</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://api.db.flexpack.org/reports/buyers_guide_table"
                target="_blank"
                download="https://api.db.flexpack.org/reports/buyers_guide_table"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Buyer's Guide Company Information</TD>
            <TD>COMPANIES</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/cinrjmrkjtcmyyfjapubqyndgcdh.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/cinrjmrkjtcmyyfjapubqyndgcdh.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Chairman's Advisory Council</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/awmeswzjfclhlwcsvtiaxtfkqpax.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/awmeswzjfclhlwcsvtiaxtfkqpax.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Circularity Committee</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/cndmivbhmpvelfnrxxlcwjwhsyiv.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/cndmivbhmpvelfnrxxlcwjwhsyiv.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Converter and International Converter Contacts</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/wzmgdxysbfoaottmbuzjabhnpdgl.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/wzmgdxysbfoaottmbuzjabhnpdgl.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Converter, International Converter and Associates Contacts</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/yqrgbdyzuyrgmxbtevvccjmvzhuk.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/yqrgbdyzuyrgmxbtevvccjmvzhuk.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Converting List</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/pnuhomahmxfcrdmqfpzxvbqwqbog.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/pnuhomahmxfcrdmqfpzxvbqwqbog.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>EHS Committee</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/vyoyylqdecuuhrpuwleefuakemmg.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/vyoyylqdecuuhrpuwleefuakemmg.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR> 
          <TR py={2}>
            <TD>ELC Emerging Leadership Council</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/mslxfpuoejskmnhwfijxbdyuyjlk.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/mslxfpuoejskmnhwfijxbdyuyjlk.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Executive Committee</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/mrjvlqdtwablqrkvlpcwzpjmchkb.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/mrjvlqdtwablqrkvlpcwzpjmchkb.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Grouped Contacts</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/tkrufbnjjjeobunlvzzxmuzwxenj.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/tkrufbnjjjeobunlvzzxmuzwxenj.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Marketing &amp; Communications Contacts</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/dxzsomtnkoqhkzpbxaklkwkqrjti.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/dxzsomtnkoqhkzpbxaklkwkqrjti.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Member Companies</TD>
            <TD>COMPANIES</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/gbhkvdmtjorzznarjdqurqsstgud.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/gbhkvdmtjorzznarjdqurqsstgud.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Member Contacts</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/qcgksdjuxedvfhkifnucatimleox.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/qcgksdjuxedvfhkifnucatimleox.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Member Sales/Dues Status (2021)</TD>
            <TD>SALES REPORTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/ahspxkcjxacvktpbkukhsranzagc.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/ahspxkcjxacvktpbkukhsranzagc.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Member Sales/Dues Status (2022)</TD>
            <TD>SALES REPORTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/hqifsqzkwqjcruznneijddiffemm.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/hqifsqzkwqjcruznneijddiffemm.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Member Sales/Dues Status (2023)</TD>
            <TD>SALES REPORTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/cepmvcfvsuhfewraguikrnloaxoe.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/cepmvcfvsuhfewraguikrnloaxoe.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Member Sales/Dues Status (2024)</TD>
            <TD>SALES REPORTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/hurxvynhopcdwlsjbfahblgqpfcv.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/hurxvynhopcdwlsjbfahblgqpfcv.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Member Summaries</TD>
            <TD>COMPANIES</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/ycyudxzompqaawprmthlnyywckkv.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/ycyudxzompqaawprmthlnyywckkv.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Officers</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/jbhprxkbyifzzhtmjoljeqsttwcu.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/jbhprxkbyifzzhtmjoljeqsttwcu.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Primary, Secondary and Authorized Contacts</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/coyujykuovnrpuufpaukeuhszges.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/coyujykuovnrpuufpaukeuhszges.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Product Stewardship Committee</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/iyoysepgtpgrafoonvdtbykaxdiy.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/iyoysepgtpgrafoonvdtbykaxdiy.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Pulse Distribution List</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/paqgulhdnyickvtdvrzahawuwqyj.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/paqgulhdnyickvtdvrzahawuwqyj.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Regulatory &amp; Government Relations Committee</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/fbghuypypltblphqeaneakzjevbl.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/fbghuypypltblphqeaneakzjevbl.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Reported Dues (2019)</TD>
            <TD>SALES REPORTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/ulwsewjmgebqeudwtulduoyqbrpf.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/ulwsewjmgebqeudwtulduoyqbrpf.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Reported Dues (2020)</TD>
            <TD>SALES REPORTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/fqhmtiugzsbhvakobphjfggikvyy.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/fqhmtiugzsbhvakobphjfggikvyy.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Resource Recovery Task Group</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/ilsqmakvmfqlayubtvvyoqludztg.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/ilsqmakvmfqlayubtvvyoqludztg.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>SOI Distribution List</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/gtvrgccyfqjdjbfntagewjgkjmav.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/gtvrgccyfqjdjbfntagewjgkjmav.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>SPMC - Sterilization Packaging Manufacturers Council</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/lopzacrgzpsztbitmjylcnfazmwb.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/lopzacrgzpsztbitmjylcnfazmwb.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>SPMC Executive Committee</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/vszhpzvhziussjraerzmlydgjfkl.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/vszhpzvhziussjraerzmlydgjfkl.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>SPMC Marketing Committee  </TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/xzywgzgyhqvijssnbgkpzgllrkmb.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/xzywgzgyhqvijssnbgkpzgllrkmb.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>SPMC Technical Committee</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/ghwfmbuzisnfgwhvsxjrdwpcpark.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/ghwfmbuzisnfgwhvsxjrdwpcpark.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR> 
          <TR py={2}>
            <TD>SPMC Sustainability Committee</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/oyvulctlqzwzannvrrqlikokevit.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/oyvulctlqzwzannvrrqlikokevit.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>SPMC – EC Converters</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/tjvcinmzegbymipmiroilsjalcld.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/tjvcinmzegbymipmiroilsjalcld.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Sponsoring List</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/ejgzttphqolqeholpqpcaxzxaahe.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/ejgzttphqolqeholpqpcaxzxaahe.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Student Design Challenge Submissions</TD>
            <TD>STUDENT DESIGN CHALLENGE</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/nbbmzztvuxdlwmwlghdflaistbhm.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/nbbmzztvuxdlwmwlghdflaistbhm.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
          <TR py={2}>
            <TD>Sustainable Packaging Strategy Task Force</TD>
            <TD>CONTACTS</TD>
            <TD>
              <S.ButtonedLink
                size="small"
                href="https://data.heroku.com/dataclips/upmifzevfnlkdeigerbcgdyjjwrj.csv"
                target="_blank"
                download="https://data.heroku.com/dataclips/upmifzevfnlkdeigerbcgdyjjwrj.csv"
              >
                <S.Flex alignItems="center">
                  <SVG.DownloadSvg />
                  Download
                </S.Flex>
              </S.ButtonedLink>
            </TD>
          </TR>
        </Table>
      </S.Panel>
    </Page>
  );
};

export default Reports;
